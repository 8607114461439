import React from "react";
import "./styles.css";

export default () => (
    <header>
        <img alt="CTX Radio Logo" className="player-image" src="./CTX_LOGO.png" />

        <div className="player-container">
            <iframe
                title="ctx-radio-player"
                src="https://mixlr.com/users/7681344/embed?autoplay=true"
                width="100%"
                height="180px"
                scrolling="no"
                frameborder="no"
                marginheight="0"
                marginwidth="0"></iframe>
        </div>
    </header>
);
