import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import './App.css';

import CrashPage from "./Pages/CrashPage";
import Player from "./Components/Player";
import Schedule from "./Components/Schedule";
import NoMatch from "./Components/NoMatch";
import TopToolbar from "./Components/TopToolbar";
function App() {
  return (
    <div className="App">
        <Router>
          <Player />
          <ErrorBoundary FallbackComponent={CrashPage}>
            <Switch>
              <Route exact path="/">
                <Schedule />
              </Route>

              <Route path="/sign_into_admin">
                <TopToolbar />
              </Route>

              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </ErrorBoundary>
        </Router>
    </div>
  );
}

export default App;
