import React from "react";
import { useLocation, Link } from "react-router-dom";

import "./styles.css";

export default () => {
    const location = useLocation();

    return (
        <section className="container">
            <Link to="/">
                <img alt="CTX Radio Logo" className="nomatch-image" src="./CTX_LOGO.png" />
            </Link>
            <h1>{location.pathname} not found</h1>
        </section>
    )
}
