import React from "react";
import "./styles.css";
export default () => {
    return (
        <div role="alert">
            <h1>Something went wrong</h1>
            <button onClick={window.location.reload}>Reload CTX</button>
        </div>
    );
}
