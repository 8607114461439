import React, { useEffect, useState } from "react";
import { format, startOfToday, parseISO, isWithinInterval } from "date-fns"

import "./styles.css"

import Monday from "../../static/_posts/Monday.json";
import Tuesday from "../../static/_posts/Tuesday.json";
import Wednesday from "../../static/_posts/Wednesday.json";
import Thursday from "../../static/_posts/Thursday.json";
import Friday from "../../static/_posts/Friday.json";
import Saturday from "../../static/_posts/Saturday.json";
import Sunday from "../../static/_posts/Sunday.json";

const ScheduleData = {
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
};

function Schedule() {
    const [events, setEvents] = useState(void 0);
    const [error] = useState(void 0);
    const [eventFilter, setEventFilter] = useState(format(startOfToday(), "EEEE"));

    useEffect(() => {
        setEvents(ScheduleData[eventFilter]["shows"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventFilter]);

    return (
        <section>
            <h1>Schedule</h1>

            <div className="schedule-container">
                <div className="schedule-tabs-container">
                    <DayTab day="Monday" filter="Monday" selectedFilter={eventFilter} onFilter={setEventFilter} />
                    <DayTab day="Tuesday" filter="Tuesday" selectedFilter={eventFilter} onFilter={setEventFilter} />
                    <DayTab day="Wednesday" filter="Wednesday" selectedFilter={eventFilter} onFilter={setEventFilter} />
                    <DayTab day="Thursday" filter="Thursday" selectedFilter={eventFilter} onFilter={setEventFilter} />
                    <DayTab day="Friday" filter="Friday" selectedFilter={eventFilter} onFilter={setEventFilter} />
                    <DayTab day="Saturday" filter="Saturday" selectedFilter={eventFilter} onFilter={setEventFilter} />
                    <DayTab day="Sunday" filter="Sunday" selectedFilter={eventFilter} onFilter={setEventFilter} />
                </div>

                {Array.isArray(events) ? (
                    <DaySchedule events={events} showLiveMarker={format(startOfToday(), "EEEE") === eventFilter}/>
                ) : error ? (
                    <h2 className="error-text">Sorry, an error occured, please try again later</h2>
                ) : (
                    <h2 className="glow">...Loading</h2>
                )}
                <p className="shedule-reload" onClick={async () => {
                    await setEvents(void 0);
                    await setEvents(ScheduleData[eventFilter]["shows"]);
                }}>reload schedule</p>
            </div>
        </section>
    );
}
export default Schedule;

function DayTab({ day, filter, selectedFilter, onFilter }) {
    const isSelected = filter === selectedFilter;
    const containerClassNames = [
        "schedule-tab-container",
        isSelected && "schedule-tab-container--selected",
    ].filter(Boolean).join(" ");
    return (
        <div className={containerClassNames} onClick={() => {
            if (selectedFilter === filter) return;

            onFilter(filter)
        }}>
            {isSelected ? (
                <p className="schedule-tab-text" style={{ textDecoration: isSelected ? "underline" : "none", fonWeight: "bold" }}><strong>{day}</strong></p>
            ) : (
                <p className="schedule-tab-text">{day}</p>
            )}
        </div>
    );
};

function DaySchedule({ events = [], showLiveMarker = false }) {
    if (!events.length) return null;

    return (
        events.map(({ starts_at, ends_at, artist, description }) => {
            const liveNow =  (
                showLiveMarker &&
                isWithinInterval(new Date(), { start: parseISO(starts_at), end: parseISO(ends_at) })
            );

            const containerClassNames = [
                "schedule-event",
                liveNow ? "schedule-event__time--selected" : ""
            ].filter(Boolean).join(" ");

            return (
                <div key={`${artist}-${starts_at}`} className={containerClassNames}>
                    <div className="schedule-event__text-container">
                        <p className="schedule-event__time">
                            {format(Date.parse(starts_at), "HH:mm")} - {format(Date.parse(ends_at), "HH:mm")}
                        </p>
                        <p className="schedule-event__summary">
                            {artist} {description}
                        </p>
                    </div>
                    {liveNow && <strong className="schedule-event__live-indicator glow" style={{ justifySelf: "flex-end"}}>Live</strong>}
                </div>
            );
        })
    )
}
